import React from 'react';
import styled, { css } from 'styled-components';

type StyledInputProps = {
    ROW?: boolean;
    width?: number;
    height?: number;
    margin?: string;
    border?: string;
    title?: string;
    optionalText?: string;
    name?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    maxLength?: number;
    disabled?: boolean;
    placeholder?: string;
};

const StyledTextarea = ({
    ROW,
    width,
    height = 100,
    margin,
    border,
    title = '',
    optionalText = '',
    name = '',
    value = '',
    onChange = () => null,
    onKeyDown = () => null,
    maxLength = 300,
    disabled = false,
    placeholder = ''
}: StyledInputProps) => {
    if (ROW) {
        return (
            <Wrapper $row={ROW} $width={width} $margin={margin}>
                {title && (
                    <RowTitle>
                        {title}
                        {optionalText && (
                            <LengthCount>{optionalText}</LengthCount>
                        )}
                    </RowTitle>
                )}
                <Textarea
                    $width={width}
                    $height={height}
                    $border={border}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    maxLength={maxLength}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper $width={width} $margin={margin}>
            {title && (
                <TitleBox>
                    <ColumnTitle>{title}</ColumnTitle>
                    <LengthCount>
                        ({value.length || 0} / {maxLength})
                    </LengthCount>
                </TitleBox>
            )}
            <Textarea
                $height={height}
                $border={border}
                name={name}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={placeholder}
            />
        </Wrapper>
    );
};

export default React.memo(StyledTextarea);

const Wrapper = styled.div<{
    $row?: boolean;
    $width?: number;
    $margin?: string;
}>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    margin: ${({ $margin }) => ($margin ? $margin : 0)};

    ${({ $row }) =>
        $row &&
        css`
            display: flex;
        `};
`;
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;
const ColumnTitle = styled.div`
    font-size: 14px;
`;
const RowTitle = styled.div`
    width: 180px;
`;
const LengthCount = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.colors.placeholder};
    margin-left: 4px;
`;
const Textarea = styled.textarea<{
    $width?: number;
    $height?: number;
    $border?: string;
}>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => $height}px;
    padding: 14px 12px;
    font-size: 15px;
    color: ${(props) => props.theme.colors.fontColor};
    border: ${(props) => props.theme.colors.border} 1px solid;
    border-radius: 4px;
    white-space: pre-wrap;
    &::placeholder {
        color: ${(props) => props.theme.colors.placeholder};
    }
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        border: ${(props) => props.theme.colors.fontColor} 1px solid;
    }
`;
