import styled from 'styled-components';

export const GrayText = styled.span`
    color: ${(props) =>
        props.theme.colors.fontColor === '#000000'
            ? props.theme.colors.darkGrayBG
            : props.theme.colors.lightGrayBG};
`;

export const BoldText = styled.div`
    margin-bottom: 80px;
    font-size: 18px;
    font-family: 'PretendardSemiBold', sans-serif;
    word-break: keep-all;
    white-space: pre-wrap;
    line-height: 1.5;
`;

export const Img = styled.img`
    display: block;
    margin: 0 auto;
    width: 50%;
`;

export const DescArea = styled.ul`
    margin-top: 60px;
    border-bottom: ${(props) => props.theme.colors.fontColor} 1px dashed;
`;

export const DescList = styled.li``;

export const MoreList = styled.li`
    margin-bottom: 32px;
    text-align: center;
    color: ${(props) =>
        props.theme.colors.fontColor === '#000000'
            ? props.theme.colors.darkGrayBG
            : props.theme.colors.lightGrayBG};
    cursor: pointer;
`;

export const Content = styled.p`
    margin-bottom: 40px;
    word-break: keep-all;
    white-space: pre-wrap;
    line-height: 1.5;
`;
