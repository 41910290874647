import {
    useState,
    useEffect,
    ChangeEvent,
    KeyboardEvent,
    Fragment
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import * as R from './Result.style';
import Animal from '@assets/images/ResultImg.svg';
import { SEND_KAKAO } from '@graphql/mutations';
import { TypeCategory, TypeCategoryText, Mutation } from '@graphql/types';
import { getResultTitle } from '@lib/enumChange';

import {
    Wrapper,
    BoldFont,
    SemiBoldFont,
    RegularFont
} from '@components/styled/StyledComponents';
import StyledInput from '@components/styled/StyledInput';
import StyledButton from '@components/styled/StyledButton';
import Loader from '@components/shared/Loader';

const Result = () => {
    const navigate = useNavigate();
    const { resultId, result } = useLocation().state;
    const [phoneNum, setPhoneNum] = useState('');
    const [loading, setLoading] = useState(false);

    const [sendKakao] = useMutation<Pick<Mutation, 'sendKakao'>>(SEND_KAKAO);

    useEffect(() => {
        if (!result) {
            setLoading(true);
        } else {
            setLoading(false);
            document
                .getElementById('top')
                ?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [result]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const { maxLength } = (e as ChangeEvent<HTMLInputElement>).target;
        setPhoneNum(value.slice(0, maxLength));
    };
    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === '-' || e.key === '+' || e.key === '.') e.preventDefault();
    };

    const onSubmit = async () => {
        if (!phoneNum || phoneNum.length < 11) {
            return alert('휴대폰 번호를 정확히 입력해주세요.');
        }
        try {
            const { data } = await sendKakao({
                variables: {
                    testResultId: resultId,
                    cellphone: phoneNum
                }
            });
            if (data?.sendKakao) {
                alert('결과가 카카오톡으로 전달되었습니다.');
                navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Wrapper id="top" $minHeight="calc(100% - 100px)">
            {loading && <Loader />}
            {!loading && (
                <>
                    <RegularFont $fontSize={18} $padding="12px 0 0">
                        <R.GrayText>나의 운동 유형 : </R.GrayText>
                        {result?.ubtiTypeKorName}
                    </RegularFont>
                    <BoldFont
                        className="notranslate"
                        $fontSize={192}
                        $margin="0 0 32px"
                    >
                        {result?.ubtiTypeName}
                    </BoldFont>
                    <R.BoldText>{result?.summary}</R.BoldText>
                    {/* TODO: 추후 교체 */}
                    <R.Img src={Animal} alt="결과" />
                    <R.DescArea>
                        {((result?.typeCategory as TypeCategory[]) || []).map(
                            (category, index) => (
                                <R.DescList key={`${index}-category`}>
                                    <SemiBoldFont
                                        $fontSize={18}
                                        $margin="0 0 12px"
                                    >
                                        {getResultTitle(
                                            category.cateNum as number
                                        )}
                                    </SemiBoldFont>
                                    <>
                                        {(
                                            (category.typeCategoryText as TypeCategoryText[]) ||
                                            []
                                        ).map((list, index) => (
                                            <Fragment
                                                key={`${index}-contents-list`}
                                            >
                                                <RegularFont>
                                                    {list.subtitle}
                                                </RegularFont>
                                                <R.Content>
                                                    {list.text}
                                                </R.Content>
                                            </Fragment>
                                        ))}
                                    </>
                                </R.DescList>
                            )
                        )}
                        <R.MoreList
                            onClick={() =>
                                navigate(`/type/detail/${result.utId}`)
                            }
                        >
                            나의 운동 성향 더보기+
                        </R.MoreList>
                    </R.DescArea>
                    <SemiBoldFont $fontSize={18} $margin="20px 0">
                        카카오톡 결과 전송하기
                    </SemiBoldFont>
                    <StyledInput
                        type="number"
                        value={phoneNum}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onWheel={(e) => e.currentTarget.blur()}
                        maxLength={11}
                        placeholder="휴대폰번호 (-)생략"
                        description="카카오톡 전송은 대한민국만 지원됩니다."
                    />
                    <StyledButton
                        title="전송하기"
                        width="100%"
                        margin="40px 0 15px"
                        onClick={onSubmit}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default Result;
