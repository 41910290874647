import { gql } from '@apollo/client';

// ubti 테스트 결과
export const SEE_MY_UBTI_TYPE = gql`
    mutation SeeMyUbtiType($gender: Int!, $testResult: [testResult]) {
        seeMyUbtiType(gender: $gender, testResult: $testResult) {
            testResultId
            ubtiType {
                utId
                createdAt
                updatedAt
                ubtiTypeName
                ubtiTypeEngName
                ubtiTypeKorName
                summary
                img
                typeCategory {
                    tcId
                    cateNum
                    typeCategoryText {
                        tcsId
                        subtitle
                        text
                    }
                }
            }
        }
    }
`;
// 문의하기
export const SEND_INQUIRY_MAIL = gql`
    mutation SendInquiryMail($email: String!, $title: String!, $text: String!) {
        sendInquiryMail(email: $email, title: $title, text: $text)
    }
`;
// 카카오톡 보내기
export const SEND_KAKAO = gql`
    mutation SendKakao($cellphone: String!, $testResultId: Int!) {
        sendKakao(cellphone: $cellphone, testResultId: $testResultId)
    }
`;
