import { gql } from '@apollo/client';

// 질문 리스트
export const SEE_QUESTION_LIST = gql`
    query SeeQuestionList {
        seeQuestionList {
            qstId
            createdAt
            updatedAt
            question
            outline
            type
            sequence
        }
    }
`;
// ubti 타입 목록
export const SEE_UBTI_TYPE_LIST = gql`
    query SeeUbtiTypeLlist {
        seeUbtiTypeLlist {
            utId
            ubtiTypeName
            ubtiTypeEngName
            ubtiTypeKorName
        }
    }
`;
// ubti 타입 상세
export const SEE_UBTI_TYPE_DETAIL = gql`
    query SeeUbtiTypeDetail($utId: Int!) {
        seeUbtiTypeDetail(utId: $utId) {
            utId
            createdAt
            updatedAt
            ubtiTypeName
            ubtiTypeEngName
            ubtiTypeKorName
            summary
            img
            typeCategory {
                tcId
                cateNum
                typeCategoryText {
                    tcsId
                    subtitle
                    text
                }
            }
        }
    }
`;
