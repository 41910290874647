export const getResultTitle = (category: number) => {
    switch (category) {
        case 1:
            return '신체 (Body Awareness & Development)';
        case 2:
            return '스타일링 (Body Type & Styling)';
        case 3:
            return '건강 (Overall Health & Wellness)';
        case 4:
            return '영양 (Nutrition & Diet)';
        case 5:
            return '운동 (Exercise & Physical Activity)';
        case 6:
            return '성격과 대인 관계 (Personality & Relationship)';
    }
};
