import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import * as W from './WorkOutType.style';
import { SEE_UBTI_TYPE_LIST } from '@graphql/queries';
import { Query } from '@graphql/types';

import Loader from '@components/shared/Loader';

const WorkOutType = () => {
    const navigate = useNavigate();
    const { data, loading } =
        useQuery<Pick<Query, 'seeUbtiTypeLlist'>>(SEE_UBTI_TYPE_LIST);
    const ubtiTypeList = data?.seeUbtiTypeLlist ?? [];

    return (
        <W.Wrapper>
            {loading && <Loader />}
            {!loading &&
                ubtiTypeList.map((list, index) => (
                    <W.List
                        key={`${list?.ubtiTypeName}-${index}`}
                        onClick={() => navigate(`/type/detail/${list?.utId}`)}
                    >
                        <W.Text>{list?.ubtiTypeKorName}</W.Text>
                        <W.Text className="notranslate">
                            {list?.ubtiTypeName}
                        </W.Text>
                    </W.List>
                ))}
        </W.Wrapper>
    );
};

export default WorkOutType;
