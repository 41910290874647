import { useState, useEffect, useCallback, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { RiMenu2Fill, RiArrowLeftLine, RiContrastFill } from 'react-icons/ri';

import KOR from '@assets/icons/icon_KOR.svg';
import ENG from '@assets/icons/icon_ENG.svg';
import { getCookie, setCookie } from '@utils/cookies';
import { isDarkMode, pageNumber } from '@store/index';

import Navigation from './Navigation';

const Header = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const darkMode = useReactiveVar(isDarkMode);
    const testPageNum = useReactiveVar(pageNumber);
    const [isOpen, setIsOpen] = useState(false);
    const [langChange, setLangChange] = useState(false);

    useEffect(() => {
        if (pathname === '/') setIsOpen(false);
    }, [pathname]);

    useEffect(() => {
        if (getCookie('googtrans')?.includes('en')) {
            setLangChange(true);
        } else {
            setLangChange(false);
        }
    }, []);

    const backButtonAction = useCallback(() => {
        if (pathname.includes('result')) {
            return navigate('/');
        }
        if (pathname !== '/test' || testPageNum === 0) {
            navigate(-1);
        } else {
            pageNumber(testPageNum - 1);
            document
                .getElementById('top')
                ?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [testPageNum, pathname]);

    const langChangeHandler = (e: MouseEvent<HTMLButtonElement>) => {
        const { value } = e.currentTarget;

        const element = document.querySelector(
            '.goog-te-combo'
        ) as HTMLSelectElement;

        if (element) {
            element.value = value;
            element.dispatchEvent(new Event('change'));
        }

        if (value.includes('ko')) {
            setLangChange(false);
            // window.location.reload();
            // return;
        } else {
            setLangChange(true);
        }
    };

    if (pathname === '/') {
        return (
            <>
                <Wrapper $justifyContent="flex-end">
                    {langChange ? (
                        <Button
                            value="ko"
                            onClick={langChangeHandler}
                            $width={40}
                            $height={40}
                            $margin="0 0 0 4px"
                        >
                            <Img
                                src={ENG}
                                alt="언어 변경"
                                $darkMode={darkMode}
                            />
                        </Button>
                    ) : (
                        <Button
                            value="en"
                            onClick={langChangeHandler}
                            $width={40}
                            $height={40}
                            $margin="0 0 0 4px"
                        >
                            <Img
                                src={KOR}
                                alt="언어 변경"
                                $darkMode={darkMode}
                            />
                        </Button>
                    )}
                    <Button
                        $width={40}
                        $height={40}
                        onClick={() => isDarkMode(!darkMode)}
                    >
                        <RiContrastFill size={20} />
                    </Button>
                    <Button
                        $width={40}
                        $height={40}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <RiMenu2Fill size={20} />
                    </Button>
                </Wrapper>
                {isOpen && <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />}
            </>
        );
    }

    return (
        <Wrapper>
            <Button onClick={backButtonAction}>
                <RiArrowLeftLine size={20} />
            </Button>
            {pathname === '/test' && (
                <>
                    <StatusBar>
                        <StatusBarGauge $width={33 * (testPageNum + 1)} />
                        <StatusBarBG />
                    </StatusBar>
                    <Percent>{`${
                        testPageNum !== 2 ? 33 * (testPageNum + 1) : 100
                    }%`}</Percent>
                </>
            )}
        </Wrapper>
    );
};

export default Header;

const Wrapper = styled.header<{ $justifyContent?: string }>`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: ${({ $justifyContent }) =>
        $justifyContent ? $justifyContent : 'space-between'};
    align-items: center;
    padding: 0 15px;
    width: 100%;
    max-width: 720px;
    height: 52px;
    background-color: ${(props) => props.theme.colors.bgColor};
    z-index: 10;
`;

const Button = styled.button<{
    $width?: number;
    $height?: number;
    $margin?: string;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    width: ${({ $width }) => ($width ? $width : 32)}px;
    height: ${({ $height }) => ($height ? $height : 32)}px;
    font-family: 'PretendardSemiBold', sans-serif;
    color: ${(props) => props.theme.colors.fontColor};
`;

const StatusBar = styled.div`
    position: relative;
    width: 220px;
    height: 9px;
`;

const StatusBarGauge = styled.div<{
    $width?: number;
}>`
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $width }) => ($width ? $width : 0)}%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.fontColor};
    border-radius: 5px;
    transition: width 0.2s;
`;

const StatusBarBG = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.theme.colors.fontColor === '#000000'
            ? props.theme.colors.lightGrayBG
            : props.theme.colors.darkGrayBG};
    border-radius: 5px;
`;

const Percent = styled.span`
    font-size: 18px;
    color: ${(props) => props.theme.colors.fontColor};
`;

const Img = styled.img<{ $darkMode: boolean }>`
    display: block;
    width: 32px;
    filter: ${({ $darkMode }) => ($darkMode ? 'invert(0)' : 'invert(1)')};
`;
