import { memo } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import Header from '@components/layout/Header';
import Footer from '@components/layout/Footer';
import Main from '@pages/Main';
import WorkOutType from '@pages/WorkOutType';
import WorkOutDetail from '@pages/WorkOutType/WorkOutDetail';
import Guide from '@pages/Guide';
import Inquiry from '@pages/Inquiry';
import Test from '@pages/Test';
import Result from '@pages/Result';

const MainRoutes = memo(() => (
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/type/list" element={<WorkOutType />} />
        <Route path="/type/detail/:id" element={<WorkOutDetail />} />
        <Route path="/guide/:id" element={<Guide />} />
        <Route path="/inquiry" element={<Inquiry />} />
        <Route path="/test" element={<Test />} />
        <Route path="/test/result" element={<Result />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
    </Routes>
));

const Router = () => {
    const { pathname } = useLocation();

    return (
        <Wrapper>
            <Header />
            <MainRoutes />
            {!(pathname === '/' || pathname.includes('result')) && <Footer />}
        </Wrapper>
    );
};

export default Router;

const Wrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 720px;
    height: 100vh;
    position: relative;
    z-index: 0;
    overflow-x: hidden;
`;
