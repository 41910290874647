import styled from 'styled-components';
import { lighten, darken } from 'polished';

export const ListWrapper = styled.ul``;

export const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    width: 100%;
    max-width: 720px;
    background-color: ${(props) => props.theme.colors.bgColor};
`;

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 4px;
    font-size: 18px;
    font-family: 'PretendardSemiBold', sans-serif;
    color: ${(props) => props.theme.colors.bgColor};
    background-color: ${(props) => props.theme.colors.fontColor};

    &:hover {
        background-color: ${(props) =>
            props.theme.colors.fontColor === '#000000'
                ? lighten(0.2, props.theme.colors.fontColor)
                : darken(0.1, props.theme.colors.fontColor)};
    }
`;

export const Img = styled.img<{ $darkMode: boolean }>`
    display: block;
    margin-left: 4px;
    width: 26px;
    filter: ${({ $darkMode }) => ($darkMode ? 'invert(0)' : 'invert(1)')};
`;
