import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RiArrowLeftLine } from 'react-icons/ri';

import Footer from './Footer';

type NavigationPropsType = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};
const Navigation = ({ isOpen, setIsOpen }: NavigationPropsType) => {
    const navigate = useNavigate();
    const [animation, setAnimation] = useState(false);

    useEffect(() => {
        if (isOpen) setAnimation(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setAnimation(false);
        setTimeout(() => {
            setIsOpen(!isOpen);
        }, 200);
    };

    return (
        <Wrapper $isOpen={animation}>
            <Header>
                <Button onClick={handleClose}>
                    <RiArrowLeftLine size={20} />
                </Button>
            </Header>
            <Menu>
                <List onClick={() => navigate('/type/list')}>
                    운동 유형 종류
                </List>
                <List onClick={() => navigate('/inquiry')}>문의</List>
            </Menu>
            <Footer />
        </Wrapper>
    );
};

export default Navigation;

const Wrapper = styled.nav<{ $isOpen: boolean }>`
    position: absolute;
    top: 0;
    right: ${({ $isOpen }) => ($isOpen ? 0 : '-100%')};
    padding: 0 15px;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.colors.bgColor};
    z-index: 10;
    transition: 0.2s;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
`;
const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.colors.fontColor};
`;
const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 152px);
`;
const List = styled.li`
    display: flex;
    align-items: center;
    margin: 6px 0;
    height: 50px;
    font-size: 22px;
    font-family: 'PretendardSemiBold', sans-serif;
    color: ${(props) => props.theme.colors.fontColor};
    cursor: pointer;
`;
