import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import * as W from './WorkOutType.style';
import { SEE_UBTI_TYPE_DETAIL } from '@graphql/queries';
import { Query, TypeCategory, TypeCategoryText } from '@graphql/types';
import { getResultTitle } from '@lib/enumChange';

import {
    Wrapper,
    SemiBoldFont,
    RegularFont
} from '@components/styled/StyledComponents';
import Loader from '@components/shared/Loader';

const WorkOutDetail = () => {
    const { id } = useParams();
    const { data, loading } = useQuery<Pick<Query, 'seeUbtiTypeDetail'>>(
        SEE_UBTI_TYPE_DETAIL,
        {
            variables: {
                utId: Number(id)
            },
            onError: (err) => {
                console.log(err);
            }
        }
    );
    const detailData = data?.seeUbtiTypeDetail ?? {};

    return (
        <Wrapper $minHeight="calc(100% - 100px)">
            {loading && <Loader />}
            {!loading && (
                <W.DescArea>
                    {((detailData?.typeCategory as TypeCategory[]) || []).map(
                        (category, index) => (
                            <W.DescList key={`${index}-category`}>
                                <SemiBoldFont $fontSize={18} $margin="0 0 12px">
                                    {getResultTitle(category.cateNum as number)}
                                </SemiBoldFont>
                                <>
                                    {(
                                        (category.typeCategoryText as TypeCategoryText[]) ||
                                        []
                                    ).map((list, index) => (
                                        <Fragment
                                            key={`${index}-contents-list`}
                                        >
                                            <RegularFont>
                                                {list.subtitle}
                                            </RegularFont>
                                            <W.Content>{list.text}</W.Content>
                                        </Fragment>
                                    ))}
                                </>
                            </W.DescList>
                        )
                    )}
                </W.DescArea>
            )}
        </Wrapper>
    );
};

export default WorkOutDetail;
