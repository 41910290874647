import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import PretendardRegular from './fonts/Pretendard/Pretendard-Regular.ttf';
import PretendardMedium from './fonts/Pretendard/Pretendard-Medium.ttf';
import PretendardSemiBold from './fonts/Pretendard/Pretendard-SemiBold.ttf';
import PretendardBold from './fonts/Pretendard/Pretendard-Bold.ttf';
import AppleSDGothicNeoBold from './fonts/AppleSDGothic/AppleSDGothicNeoB.ttf';
import AppleSDGothicNeoLight from './fonts/AppleSDGothic/AppleSDGothicNeoL.ttf';
import AppleSDGothicNeoMedium from './fonts/AppleSDGothic/AppleSDGothicNeoM.ttf';
import AppleSDGothicNeoRegular from './fonts/AppleSDGothic/AppleSDGothicNeoR.ttf';
import AppleSDGothicNeoSemiBold from './fonts/AppleSDGothic/AppleSDGothicNeoSB.ttf';

export default createGlobalStyle`
  ${reset};

  @font-face {
    font-family: 'PretendardRegular';
    src: url(${PretendardRegular});
  }

  @font-face {
    font-family: 'PretendardMedium';
    src: url(${PretendardMedium});
  }

  @font-face {
    font-family: 'PretendardSemiBold';
    src: url(${PretendardSemiBold});
  }

  @font-face {
    font-family: 'PretendardBold';
    src: url(${PretendardBold});
  }

  @font-face {
    font-family: 'AppleSDGothicNeoBold';
    src: url(${AppleSDGothicNeoBold});
  }
  
  @font-face {
    font-family: 'AppleSDGothicNeoSemiBold';
    src: url(${AppleSDGothicNeoSemiBold});
  }
  
  @font-face {
    font-family: 'AppleSDGothicNeoMedium';
    src: url(${AppleSDGothicNeoMedium});
  }
  
  @font-face {
    font-family: 'AppleSDGothicNeoRegular';
    src: url(${AppleSDGothicNeoRegular});
  }
  
  @font-face {
    font-family: 'AppleSDGothicNeoLight';
    src: url(${AppleSDGothicNeoLight});
  }

  * {
    box-sizing: border-box;
    white-space: nowrap;
  }

  body {
    width: 100%;
    font-size: 15px;
    font-family: PretendardRegular, sans-serif;
    color: ${({ theme }) => theme.colors.fontColor};
    background-color: ${({ theme }) => theme.colors.bgColor};
    scrollbar-width: none; // 파이어폭스
    -ms-overflow-style: none; // 익스플로러
    body::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.fontColor};
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  input,
  textarea {
    font-family: 'PretendardMedium', sans-serif;
    border: none;
    outline: none;
    background-color: inherit;

    &::placeholder {
      font-size: 15px;
      font-family: 'PretendardRegular', 'SF Pro Display', sans-serif;
      color: ${({ theme }) => theme.colors.placeholder};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.placeholder};
      background-color: ${({ theme }) => theme.colors.border};
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  button {
    font-size: 16px;
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  select {
    font-size: 14px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }

  li {
    list-style: none;
  }

  font{
    white-space: pre-line;
  }
`;
