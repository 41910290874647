import styled from 'styled-components';

export const Wrapper = styled.main`
    padding: 64px 15px 12px;
    width: 100%;
    min-height: calc(100% - 100px);
`;
export const Contents = styled.div`
    font-size: 18px;
    color: ${(props) => props.theme.colors.fontColor};
    white-space: pre-wrap;
`;
