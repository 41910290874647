import styled from 'styled-components';

export const Wrapper = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 64px 15px 25px;
    width: 100%;
    min-height: 100%;
`;
export const LogoImg = styled.img<{ $darkMode: boolean }>`
    display: block;
    width: 100%;
    filter: ${({ $darkMode }) => ($darkMode ? 'invert(0)' : 'invert(1)')};
`;
export const Img = styled.img<{ $darkMode: boolean }>`
    display: block;
    margin-bottom: 9px;
    width: 72px;
    height: 72px;
    filter: ${({ $darkMode }) => ($darkMode ? 'invert(0)' : 'invert(1)')};
`;
export const Guide = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: calc(100% - 207px);
`;
export const GuideList = styled.li`
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 18px;
    font-family: 'PretendardSemiBold', sans-serif;
    color: ${(props) => props.theme.colors.fontColor};
    cursor: pointer;
`;
export const GenderSelect = styled.div``;
export const Description = styled.div`
    margin-bottom: 32px;
    font-size: 18px;
    font-family: 'PretendardSemiBold', sans-serif;
    text-align: center;
    color: ${(props) => props.theme.colors.fontColor};
`;
export const Button = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 157px;
    border: ${(props) => props.theme.colors.fontColor} 1px dashed;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.fontColor};
`;
