import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';

import * as T from './Test.style';
import NEXT from '@assets/icons/nextArrow.svg';
import { isDarkMode, pageNumber } from '@store/index';
import { SEE_QUESTION_LIST } from '@graphql/queries';
import { SEE_MY_UBTI_TYPE } from '@graphql/mutations';
import { Query, Mutation } from '@graphql/types';

import { Wrapper } from '@components/styled/StyledComponents';
import TestList from '@components/feature/Test/TestList';
import Loader from '@components/shared/Loader';

const Test = () => {
    const navigate = useNavigate();
    const { gender } = useLocation().state;
    const darkMode = useReactiveVar(isDarkMode);
    const testPageNum = useReactiveVar(pageNumber);
    const [answer, setAnswer] = useState<Record<string, number>>({});
    const answerLength = Object.keys(answer).length;

    const { data, loading } =
        useQuery<Pick<Query, 'seeQuestionList'>>(SEE_QUESTION_LIST);
    const questionList = data?.seeQuestionList ?? [];

    const [seeMyUbtiType] =
        useMutation<Pick<Mutation, 'seeMyUbtiType'>>(SEE_MY_UBTI_TYPE);

    // 데이터 초기화 시 테스트 첫 페이지 이동
    useEffect(() => {
        if (!answerLength) pageNumber(0);
    }, [answerLength]);

    // 답안지 제출
    const handleGetResult = async () => {
        const testResult = questionList.map((list) => ({
            qstId: list?.qstId as number,
            ansVal: answer[`q-${list?.qstId as number}`]
        }));

        try {
            const { data } = await seeMyUbtiType({
                variables: { gender, testResult }
            });
            if (data?.seeMyUbtiType) {
                navigate('/test/result', {
                    state: {
                        resultId: data?.seeMyUbtiType?.testResultId,
                        result: data?.seeMyUbtiType?.ubtiType
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    // 다음 페이지 이동
    const handleNextPage = async () => {
        if (answerLength < (testPageNum + 1) * 8) {
            alert('항목을 전부 선택해주세요.');
            return;
        } else if (testPageNum < 2) {
            pageNumber(testPageNum + 1);
            document
                .getElementById('top')
                ?.scrollIntoView({ behavior: 'smooth' });
        } else if (testPageNum === 2 && answerLength === 24) {
            handleGetResult();
        }
    };

    return (
        <Wrapper id="top" $minHeight="calc(100% - 100px)">
            {loading && <Loader />}
            {!loading && (
                <>
                    <T.ListWrapper>
                        {questionList
                            .slice(testPageNum * 8, (testPageNum + 1) * 8)
                            .map((list, index) => (
                                <TestList
                                    key={index}
                                    list={list}
                                    answer={answer}
                                    setAnswer={setAnswer}
                                />
                            ))}
                    </T.ListWrapper>
                    <T.ButtonWrapper>
                        <T.Button onClick={handleNextPage}>
                            다음
                            <T.Img
                                src={NEXT}
                                alt="다음 화살표"
                                $darkMode={darkMode}
                            />
                        </T.Button>
                    </T.ButtonWrapper>
                </>
            )}
        </Wrapper>
    );
};

export default Test;
