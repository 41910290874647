import { DefaultTheme } from 'styled-components';

const commonColors = {
    whiteColor: '#FFFFFF',
    blackColor: '#000000',
    border: '#E5E5E5',
    subText: '#8E8E8E',
    placeholder: '#B3B3B3',
    lightGrayBG: '#D5D5D5',
    darkGrayBG: '#4B4B4B',
    activeBlue: '#15A9FF'
};

// lightMode가 default
export const lightMode: DefaultTheme = {
    colors: {
        // General
        bgColor: '#FFFFFF',
        fontColor: '#000000',
        ...commonColors
    }
};

export const darkMode: DefaultTheme = {
    colors: {
        // General
        bgColor: '#000000',
        fontColor: '#ffffff',
        ...commonColors
    }
};

export type ColorsType = {
    whiteColor: string;
    blackColor: string;
    bgColor: string;
    fontColor: string;
    placeholder: string;
    border: string;
    subText: string;
    lightGrayBG: string;
    darkGrayBG: string;
    activeBlue: string;
};
