import { useState, useEffect, memo, Dispatch, SetStateAction } from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';

import { pageNumber } from '@store/index';
import { onChangeEventType } from '@utils/types';

import StyledRadio from '@components/styled/StyledRadio';

type TestListProps = {
    list: any;
    answer: Record<string, number>;
    setAnswer: Dispatch<SetStateAction<Record<string, number>>>;
};
const TestList = ({ list, answer, setAnswer }: TestListProps) => {
    const testPageNum = useReactiveVar(pageNumber);
    const [checkedValue, setCheckedValue] = useState(0);
    const { qstId, question } = list;

    // 답변 여부에 따른 체크 처리
    useEffect(() => {
        if (answer[`q-${qstId}`]) {
            setCheckedValue(answer[`q-${qstId}`]);
        } else {
            setCheckedValue(0);
        }
    }, [testPageNum, answer, qstId]);

    const handleSelectAnswer = (e: onChangeEventType) => {
        const { name, value } = e.target;
        setCheckedValue(+value);
        setAnswer((prev: Record<string, number>) => ({
            ...prev,
            [name]: +value
        }));
    };

    return (
        <List>
            <Question>{question}</Question>
            <Selection>
                {[...Array(5)].map((_, index) => {
                    const first = index === 0;
                    const last = index === 4;
                    const large = first || last;
                    const middle = index === 1 || index === 3;

                    return (
                        <StyledRadio
                            key={`a-${qstId}-${index + 1}`}
                            radioId={`a-${qstId}-${index + 1}`}
                            name={`q-${qstId}`}
                            value={index + 1}
                            checked={checkedValue}
                            size={large ? 36 : middle ? 30 : 24}
                            margin={large ? '' : middle ? '3px 0 0' : '6px 0 0'}
                            label={first ? '비동의' : last ? '동의' : ''}
                            onChange={handleSelectAnswer}
                        />
                    );
                })}
            </Selection>
        </List>
    );
};

export default memo(TestList);

const List = styled.li`
    padding: 32px 0;
    border-bottom: ${(props) => props.theme.colors.fontColor} 1px dashed;
`;

const Question = styled.div`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5;
    font-family: 'PretendardSemiBold', sans-serif;
    text-align: center;
    white-space: pre-wrap;
    word-break: keep-all;
`;

const Selection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
