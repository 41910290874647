import React, { ChangeEvent, memo } from 'react';
import styled, { css } from 'styled-components';

type StyledRadioProps = {
    radioId: string;
    label?: string;
    fontColor?: string;
    fontFamily?: string;
    size?: number;
    margin?: string;
    name: string;
    value?: any;
    checked?: any;
    dataValue?: string;
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

const StyledRadio = ({
    radioId,
    label,
    margin,
    size = 20,
    name,
    value,
    checked,
    onChange,
    dataValue = '',
    disabled,
    fontColor
}: StyledRadioProps) => {
    return (
        <Wrapper $margin={margin}>
            <Label htmlFor={radioId}>
                <Marker $checked={value === checked} $size={size} />
                <Input
                    id={radioId}
                    type="radio"
                    name={name}
                    value={value}
                    onChange={onChange}
                    data-value={dataValue}
                    disabled={disabled}
                    checked={value === checked}
                />
                {label && <Text $fontColor={fontColor}>{label}</Text>}
            </Label>
        </Wrapper>
    );
};

export default memo(StyledRadio);

const Wrapper = styled.div<{ $margin?: string }>`
    display: flex;
    align-items: center;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
`;
const Label = styled.label`
    color: ${(props) => props.theme.colors.fontColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;
const Marker = styled.div<{ $checked: boolean; $size: number }>`
    position: relative;
    width: ${({ $size }) => ($size ? $size : 20)}px;
    height: ${({ $size }) => ($size ? $size : 20)}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.lightGrayBG};

    ${({ $checked, $size }) =>
        $checked &&
        css`
            border: 2px solid ${(props) => props.theme.colors.activeBlue};
            background-color: ${(props) => props.theme.colors.whiteColor};
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: ${$size === 36 ? 20 : $size === 30 ? 16 : 12}px;
                height: ${$size === 36 ? 20 : $size === 30 ? 16 : 12}px;
                border-radius: 50%;
                background-color: ${(props) => props.theme.colors.activeBlue};
            }
        `};
`;
const Input = styled.input`
    display: none;
`;
const Text = styled.div<{ $fontColor?: string }>`
    margin-top: 8px;
`;
