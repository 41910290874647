import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { ThemeProvider } from 'styled-components';

import { darkMode, lightMode } from '@styles/theme';
import GlobalStyles from '@styles/GlobalStyles';
import { isDarkMode } from '@store';

import Router from './Router';

const App = () => {
    const { pathname } = useLocation();
    const defaultTheme = useReactiveVar(isDarkMode);

    useEffect(() => {
        document.oncontextmenu = () => {
            return false;
        };
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <ThemeProvider theme={defaultTheme ? darkMode : lightMode}>
            <GlobalStyles />
            <Router />
        </ThemeProvider>
    );
};

export default App;
