import { useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import * as M from './Main.style';
import { setCookie } from '@utils/cookies';
import { isDarkMode } from '@store/index';
import Logo from '@assets/images/Logo.svg';
import Male from '@assets/icons/icon_male.svg';
import Female from '@assets/icons/icon_female.svg';

import { FlexBox } from '@components/styled/StyledComponents';

const Main = () => {
    const darkMode = useReactiveVar(isDarkMode);
    const navigate = useNavigate();
    // const [gender, setGender] = useState('');

    const guideHandler = (e: SyntheticEvent) => {
        if (!(e.currentTarget instanceof HTMLLIElement)) return;
        const id = e.currentTarget.dataset.id as string;
        const value = e.currentTarget.dataset.value as string;
        navigate(`/guide/${id}`, {
            state: {
                title: value
            }
        });
    };

    const selectGenderHandler = (e: SyntheticEvent) => {
        if (!(e.currentTarget instanceof HTMLButtonElement)) return;
        const value = e.currentTarget.dataset.value as string;
        navigate('/test', {
            state: {
                gender: +value
            }
        });
    };

    return (
        <M.Wrapper>
            <M.LogoImg src={Logo} alt="logo" $darkMode={darkMode} />
            <M.Guide>
                <M.GuideList
                    data-id="1"
                    data-value="가이드 First"
                    onClick={guideHandler}
                >
                    가이드 First
                </M.GuideList>
                <M.GuideList
                    data-id="2"
                    data-value="가이드 Second"
                    onClick={guideHandler}
                >
                    가이드 Second
                </M.GuideList>
                <M.GuideList
                    data-id="3"
                    data-value="가이드 Third"
                    onClick={guideHandler}
                >
                    가이드 Third
                </M.GuideList>
            </M.Guide>
            <M.GenderSelect>
                <M.Description>
                    검사 시작 전, 성별을 선택해주세요.
                </M.Description>
                <FlexBox $justifyContent="center" $gap="30px">
                    <M.Button data-value={1} onClick={selectGenderHandler}>
                        <M.Img src={Male} alt="남성" $darkMode={darkMode} />
                        남성
                    </M.Button>
                    <M.Button data-value={2} onClick={selectGenderHandler}>
                        <M.Img src={Female} alt="여성" $darkMode={darkMode} />
                        여성
                    </M.Button>
                </FlexBox>
            </M.GenderSelect>
        </M.Wrapper>
    );
};

export default Main;
