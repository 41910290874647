import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

// 이메일 정규식
export const isEmail = (email: string) => {
    const emailRegex =
        /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
};

//에러 핸들링
export const errorHandler = (error: ApolloError | GraphQLError | Error) => {
    switch (error.message) {
        // 공통 에러
        case 'err_05':
            alert('등록에 실패하였습니다.');
            break;
        case 'err_06':
            alert('조회에 실패하였습니다.');
            break;
        case 'err_22':
            alert('결과 등록 및 조회에 실패하였습니다.');
            break;
        case 'err_23':
            alert('문의메일 발송에 실패하였습니다.');
            break;
        case 'err_24':
            alert('카카오톡 발송에 실패하였습니다. 관리자에게 문의하세요.');
            break;
        default:
            alert(`server : ${error.message}`);
    }
};
