import styled from 'styled-components';

const Footer = () => {
    return (
        <Wrapper className="notranslate">
            Copyright ⓒ wookmini All right reserved.
        </Wrapper>
    );
};

export default Footer;

const Wrapper = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    font-size: 15px;
    color: ${(props) => props.theme.colors.subText};
`;
