import { useLocation } from 'react-router-dom';

import * as G from './Guide.style';
import { BoldFont } from '@components/styled/StyledComponents';

const Guide = () => {
    const { title } = useLocation().state;

    return (
        <G.Wrapper>
            <BoldFont $fontSize={18} $margin="0 0 20px">
                {title}
            </BoldFont>
            <G.Contents>준비중입니다.</G.Contents>
        </G.Wrapper>
    );
};

export default Guide;
