import styled from 'styled-components';

export const Wrapper = styled.ul`
    padding-top: 52px;
    min-height: calc(100% - 100px);
`;

export const List = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    height: 48px;
    border-bottom: ${(props) => props.theme.colors.fontColor} 1px dashed;
    cursor: pointer;
`;

export const Text = styled.span``;

export const DescArea = styled.ul`
    margin-top: 20px;
`;

export const DescList = styled.li``;

export const Content = styled.p`
    margin-bottom: 40px;
    word-break: keep-all;
    white-space: pre-wrap;
    line-height: 1.5;
`;
