import {
    ApolloClient,
    InMemoryCache,
    from,
    createHttpLink
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { GraphQLError } from 'graphql';

import { errorHandler } from '@utils/common';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach((error) => {
            const { message, locations, path } = error;
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            errorHandler(error as GraphQLError);
        });
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const timeoutLink = new ApolloLinkTimeout(30000);

// HTTP link talks to the server
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_SERVER_URL,
    credentials: 'include',
    headers: {
        'Apollo-Require-Preflight': 'true'
    }
});

const client = new ApolloClient({
    link: from([errorLink, timeoutLink, httpLink]),
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache'
        }
    },
    cache: new InMemoryCache()
});

export default client;
