import React, { ForwardedRef, forwardRef, memo } from 'react';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import Loader from '@components/shared/Loader';

type StyledButtonProps = {
    loading?: boolean;
    width?: number | string;
    height?: number;
    fontSize?: number;
    fontWeight?: string;
    fontFamily?: string;
    fontColor?: string;
    padding?: string;
    margin?: string;
    border?: string;
    borderRadius?: number;
    bgColor?: string;
    cursor?: string;
    title?: string;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};
type ButtonType = {
    $width?: number | string;
    $height?: number;
    $fontSize?: number;
    $fontFamily?: string;
    $fontWeight?: string;
    $fontColor?: string;
    $padding?: string;
    $border?: string;
    $borderRadius?: number;
    $margin?: string;
    $bgColor?: string;
    $cursor?: string;
};

const StyledButton = forwardRef(
    (
        {
            loading,
            width,
            height = 50,
            fontSize = 15,
            fontWeight,
            fontFamily = 'AppleSDGothicNeoBold',
            fontColor,
            padding = '0 12px',
            margin = '0',
            border = 'none',
            borderRadius = 4,
            bgColor,
            cursor = 'pointer',
            title = '',
            disabled = false,
            onClick = () => null
        }: StyledButtonProps,
        ref: ForwardedRef<HTMLButtonElement>
    ) => {
        return (
            <Button
                type="button"
                $width={width}
                $height={height}
                $fontSize={fontSize}
                $fontWeight={fontWeight}
                $fontFamily={fontFamily}
                $fontColor={fontColor}
                $padding={padding}
                $margin={margin}
                $border={border}
                $borderRadius={borderRadius}
                $bgColor={bgColor}
                $cursor={cursor}
                ref={ref}
                onClick={onClick}
                disabled={disabled || loading}
            >
                {loading ? <Loader BUTTON /> : title}
            </Button>
        );
    }
);

export default memo(StyledButton);

const Button = styled.button<ButtonType>`
    width: ${({ $width }) => ($width ? `${$width}` : 'auto')};
    height: ${({ $height }) => $height}px;
    font-size: ${({ $fontSize }) => $fontSize}px;
    font-family: ${({ $fontFamily }) => $fontFamily}, sans-serif;
    font-weight: ${({ $fontWeight }) => $fontWeight};
    color: ${(props) =>
        props.$fontColor ? props.$fontColor : props.theme.colors.bgColor};
    padding: ${({ $padding }) => $padding};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ $border }) => $border};
    border-radius: ${({ $borderRadius }) => $borderRadius}px;
    margin: ${({ $margin }) => $margin};
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : props.theme.colors.fontColor};
    cursor: ${({ $cursor }) => $cursor};

    &:hover {
        background-color: ${(props) =>
            !props.disabled &&
            (props.$bgColor
                ? darken(0.05, props.$bgColor)
                : props.theme.colors.fontColor === '#000000'
                ? lighten(0.2, props.theme.colors.fontColor)
                : darken(0.1, props.theme.colors.fontColor))};
    }

    &:active {
        background-color: ${(props) =>
            !props.disabled &&
            darken(0.05, props.$bgColor ?? props.theme.colors.fontColor)};
    }

    &:disabled {
        color: ${(props) => props.theme.colors.fontColor};
        background-color: ${(props) => props.theme.colors.border};
        cursor: auto;
    }
`;
