import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { SEND_INQUIRY_MAIL } from '@graphql/mutations';
import { Mutation } from '@graphql/types';
import { isEmail } from '@utils/common';
import { onChangeEventType } from '@utils/types';

import { Wrapper, BoldFont } from '@components/styled/StyledComponents';
import StyledInput from '@components/styled/StyledInput';
import StyledTextarea from '@components/styled/StyledTextarea';
import StyledButton from '@components/styled/StyledButton';

const Inquiry = () => {
    const navigate = useNavigate();
    const [validationMsg, setValidationMsg] = useState('');
    const [inputs, setInputs] = useState({
        email: '',
        title: '',
        content: ''
    });

    const [sendInquiryMail] =
        useMutation<Pick<Mutation, 'sendInquiryMail'>>(SEND_INQUIRY_MAIL);

    const onChange = (e: onChangeEventType) => {
        const { name, value } = e.target;
        setInputs((prev) => ({ ...prev, [name]: value }));
        if (name === 'email') {
            setValidationMsg(
                isEmail(inputs.email)
                    ? ''
                    : '@를 포함한 전체 이메일을 입력해주세요.'
            );
        }
    };

    const onSubmit = async () => {
        const { email, title, content } = inputs;

        if (!email) {
            return alert('이메일을 입력해주세요.');
        } else if (!isEmail(email)) {
            return alert('이메일을 정확히 입력해주세요.');
        } else if (!title) {
            return alert('제목을 입력해주세요.');
        } else if (!content) {
            return alert('내용을 입력해주세요.');
        }

        try {
            const { data } = await sendInquiryMail({
                variables: {
                    email,
                    title,
                    text: content
                }
            });
            if (data?.sendInquiryMail) {
                alert('전송이 완료되었습니다. 빠른시간 안에 답변드리겠습니다.');
                navigate('/');
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Wrapper $minHeight="calc(100% - 100px)">
            <BoldFont $fontSize={18} $padding="12px 0 32px">
                UBTI 문의하기
            </BoldFont>
            <StyledInput
                name="email"
                value={inputs.email}
                onChange={onChange}
                placeholder="이메일 입력"
                description={validationMsg}
                margin="0 0 20px"
            />
            <StyledInput
                name="title"
                value={inputs.title}
                onChange={onChange}
                placeholder="제목 입력"
                maxLength={80}
                margin="0 0 20px"
            />
            <StyledTextarea
                name="content"
                value={inputs.content}
                onChange={onChange}
                placeholder="내용 입력"
                maxLength={500}
                margin="0 0 40px"
            />
            <StyledButton title="전송하기" onClick={onSubmit} width={'100%'} />
        </Wrapper>
    );
};

export default Inquiry;
