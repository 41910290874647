import styled from 'styled-components';

type FontType = {
    $fontSize?: number;
    $fontColor?: string;
    $margin?: string;
    $padding?: string;
};

type FlexBoxType = {
    $width?: string;
    $flex?: number;
    $flexDirection?: string;
    $alignItems?: string;
    $justifyContent?: string;
    $flexWrap?: string;
    $gap?: string;
    $margin?: string;
    $border?: string;
};

export const Wrapper = styled.main<{ $minHeight?: string }>`
    /* display: flex; */
    padding: 52px 15px 0;
    width: 100%;
    min-height: ${({ $minHeight }) => ($minHeight ? $minHeight : 'auto')};
`;
export const BoldFont = styled.h1<FontType>`
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : 16)}px;
    color: ${(props) => props.theme.colors.fontColor};
    font-family: 'PretendardSemiBold', 'SF Pro Display', sans-serif;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    padding: ${({ $padding }) => ($padding ? $padding : 0)};
`;
export const SemiBoldFont = styled.h3<FontType>`
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : 16)}px;
    color: ${(props) => props.theme.colors.fontColor};
    font-family: 'PretendardSemiBold', 'SF Pro Display', sans-serif;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    padding: ${({ $padding }) => ($padding ? $padding : 0)};
`;
export const MediumFont = styled.div<FontType>`
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : 16)}px;
    color: ${(props) => props.theme.colors.fontColor};
    font-family: 'PretendardMedium', 'SF Pro Display', sans-serif;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    padding: ${({ $padding }) => ($padding ? $padding : 0)};
`;
export const RegularFont = styled.div<FontType>`
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : 16)}px;
    color: ${(props) => props.theme.colors.fontColor};
    font-family: 'PretendardRegular', 'SF Pro Display', sans-serif;
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    padding: ${({ $padding }) => ($padding ? $padding : 0)};
`;
export const FlexBox = styled.div<FlexBoxType>`
    width: ${({ $width }) => ($width ? $width : '100%')};
    display: flex;
    flex: ${({ $flex }) => ($flex ? $flex : 1)};
    flex-direction: ${({ $flexDirection }) =>
        $flexDirection ? $flexDirection : 'row'};
    align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : 'center')};
    justify-content: ${({ $justifyContent }) =>
        $justifyContent ? $justifyContent : 'flex-start'};
    flex-wrap: ${({ $flexWrap }) => ($flexWrap ? $flexWrap : 'unset')};
    gap: ${({ $gap }) => ($gap ? $gap : 0)};
    margin: ${({ $margin }) => ($margin ? $margin : 0)};
    border: ${({ $border }) => ($border ? '1px solid red' : 'none')};
`;
