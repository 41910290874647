import { Cookies } from 'react-cookie';
import { CookieSerializeOptions } from 'cookie';

const cookies = new Cookies();

export const setCookie = (name: string, value: string, options?: any) => {
    return cookies.set(name, value, { ...options });
};

export const getCookie = (name: string) => {
    return cookies.get(name);
};

export const removeCookie = (name: string, option?: CookieSerializeOptions) => {
    cookies.remove(name, {
        path: '/',
        expires: new Date(0), // 과거 시간으로 설정
        maxAge: -1, // 음수 값으로 설정
        ...option
    });
};
